var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"value") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            \""
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\": \""
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0)) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":37},"end":{"line":6,"column":66}}})) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return ",";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<script type=\"text/javascript\" id=\"miDataLayer\" data-invoke-flush=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"invokeFlushErrors") : depth0), depth0))
    + "\"\n    data-flush-url=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"flushErrorsURL") : depth0), depth0))
    + "\">\n        var "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"varName") : depth0), depth0))
    + " = {\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"dataProperties") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":17}}})) != null ? stack1 : "")
    + "        };\n        var "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"mvpOfferVarName") : depth0), depth0))
    + " = "
    + alias2(__default(require("../../../../../../core/helpers/parseJson.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"mvpOfferList") : depth0),{"name":"parseJson","hash":{},"data":data,"loc":{"start":{"line":10,"column":34},"end":{"line":10,"column":60}}}))
    + "; \n</script>\n";
},"useData":true});